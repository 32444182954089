import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { getStringWithoutHash } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/helper';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import CriticalAnalysisIntensity from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity';
import { LoaderContainer } from 'components/common';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import NoData from 'components/common/NoData/noData';
import React, { useEffect, useMemo, useState } from 'react';
import {
    useQueryActivitiesWeight,
    useQueryMilestoneWeight,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramProgressOverview/queries/progressOverviewQuery';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import SwitchComponent from 'components/common/Switch/Switch';
import { useAnnotationStore } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/store/annotationStore';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity.module.scss';
import { List } from 'components/common/ListWrapper/list';

const activityTypeOptions = [
    { label: 'Milestones', value: 'milestones' },
    { label: 'ALL', value: 'all' },
];

const ActivitiesCompleteScurveGraph = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps): JSX.Element => {
    const [activityTypeSelection, setActivityTypeSelection] = useState<ISelectOption<string>>({
        label: 'ALL',
        value: 'all',
    });
    const isShowActivitiesAnnotation = useAnnotationStore((store) => store.isShowActivitiesAnnotation);
    const { setIsShowActivitiesAnnotation } = useAnnotationStore();
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes,
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );
    const { data: activitiesWeight, isFetching: activitiesWeightLoading } = useQueryActivitiesWeight({
        id: idList,
        wbsId: -1,
        latestVersion: latestVersionId,
        comparedVersion: compareVersionId || latestVersionId,
        filter: {
            filters,
        },
    });

    const { data: milestoneWeight, isFetching: milestoneWeightLoading } = useQueryMilestoneWeight({
        id: idList,
        wbsId: -1,
        latestVersion: latestVersionId,
        comparedVersion: compareVersionId || latestVersionId,
        filter: {
            filters,
        },
    });

    const id = 'ActivitiesCompletionSCurve';
    const title = 'Activities Completion S-Curve';
    const componentKey = 'activitiesCompletionSCurve';
    const selectedData = activityTypeSelection.value === 'all' ? activitiesWeight : milestoneWeight;
    const isLoading = activitiesWeightLoading && milestoneWeightLoading;
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);
    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={title}
                tooltip={selectedData?.info}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <div className={classes.selectWrapper} key={'activitySelection'}>
                        <div className={classes.title}>Activity type:</div>
                        <List<string>
                            onChange={setActivityTypeSelection}
                            options={activityTypeOptions}
                            value={activityTypeSelection}
                        />
                    </div>,
                    <div className={classes.annotationFilter} key={'annotations'}>
                        {isShowActivitiesAnnotation ? 'Hide Annotations' : 'Show Annotations'}
                        <SwitchComponent
                            onChange={(event) => setIsShowActivitiesAnnotation(event.target.checked)}
                            checked={isShowActivitiesAnnotation}
                        />
                    </div>,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={filters}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formattedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                    ) : null,
                ]}
            >
                {selectedData?.dataset?.length > 0 && (
                    <CriticalAnalysisIntensity data={selectedData} isShowAnnotation={isShowActivitiesAnnotation} />
                )}
                {isLoading && !selectedData && (
                    <LoaderContainer>
                        <OverlayWithSpinner />
                    </LoaderContainer>
                )}
                {!isLoading && (!selectedData || selectedData?.dataset?.length === 0) && <NoData />}
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};
export default React.memo(ActivitiesCompleteScurveGraph);
